import React from 'react';
import _ from 'lodash';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from '@emotion/styled';
import Img from 'gatsby-image';

import Layout from '../components/layout';

const Date = styled.time`
  font-size: 0.75rem;
`;

const Title = styled.h1`
  margin-bottom: 0;
`;

export default ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  let featuredImgFluid = _.get(frontmatter, [
    'featuredImage',
    'childImageSharp',
    'fluid'
  ]);

  return (
    <Layout>
      <article>
        <Helmet>
          <title>{frontmatter.title}</title>
        </Helmet>
        <Title>{frontmatter.title}</Title>
        <Date>{frontmatter.date}</Date>
        {featuredImgFluid && <Img fluid={featuredImgFluid} />}
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </article>
    </Layout>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 420, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
